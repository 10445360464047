import React from "react"
import { graphql } from "gatsby"

export default ({ data: { prismicBlogpost } }) => {
  const { data } = prismicBlogpost
  return (
    <React.Fragment>
      <h1>{data.title.text}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.body.html }} />
    </React.Fragment>
  )
}

export const query = graphql`
  query BlogpostBySlug($uid: String!) {
    prismicBlogpost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        body {
          html
        }
      }
    }
  }
`
